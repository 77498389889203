import Navigation from "./navigation";

function Header(){

    function handleMenuClick() {
        let container = document.querySelector('nav')
        if(!container.classList.contains('active')) {
            /** Show the container. */
            container.classList.add('active')
            container.style.height = "auto"

            /** Get the computed height of the container. */
            let height = container.clientHeight + "px"

            /** Set the height of the content as 0px, */
            /** so we can trigger the slide down animation. */
            container.style.height = "0px"

            /** Do this after the 0px has applied. */
            /** It's like a delay or something. MAGIC! */
            setTimeout(() => {
                container.style.height = height
            }, 0)

            /** Slide up. */
        } else {
            /** Set the height as 0px to trigger the slide up animation. */
            container.style.height = "0px"

            /** Remove the `active` class when the animation ends. */
            container.addEventListener('transitionend', () => {
                container.classList.remove('active')
            }, {once: true})
        }
    }

    return (
        <header>
            <div className="hero">
                <h1>Dr Heva Chan</h1>
                <h2>Composer, Performer, Musicologist, Music Educator</h2>
            </div>
            <div className="menu">
                <button className="menu-button" onClick={handleMenuClick}>Menu</button>
                <Navigation/>
            </div>
        </header>
    )

}

export default Header;
