import heva from '../img/heva.jpg';

function Callout(){
    return(
        <div className="callout">
            <div>
                <img src={heva} alt="Dr Heva Chan"/>
                <h2>Request a trial lesson today!</h2>
                <h3>0425 292 386</h3>
                <h4>Located in Blacktown</h4>
            </div>
        </div>
    )
}
export default Callout;
