import React, {useState} from 'react';
import Callout from "./callout";
import tracks from "./data/data-tracks.json";

function PagePlaylist() {
    const [currentTrack, setCurrentTrack] = useState({
        "title": "Select a track",
        "src": "#",
        "description":"--"
    })

    const AllTracks = tracks.map((track, index)=>
        <li key={index} data-track={index} onClick={handlePlaylistChange}>
            {track.title}
        </li>
    )

    function handlePlaylistChange(e){
        let selectedTrack = e.target.dataset.track;
        setCurrentTrack(tracks[selectedTrack])
        document.querySelector(".player audio").load();
    }

    return (
        <main>
            <div className="content">
                <h3>Compositions</h3>
                <div className="compositions">
                    <div className="playlist">
                        <ol>
                            {AllTracks}
                        </ol>
                    </div>
                    <figure className="player">
                        <audio autoPlay controls>
                            <source src={process.env.PUBLIC_URL + "/tracks/" + currentTrack.src} type="audio/mpeg"/>
                            Your browser does not support the audio element.
                        </audio>
                        <h4>{currentTrack.title}</h4>
                        <p>
                            {currentTrack.description}
                        </p>
                    </figure>

                </div>
            </div>
            <Callout/>

        </main>
    )
}

export default PagePlaylist;
