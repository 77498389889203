import Callout from "./callout";


function PageGrid(props){
    const cards = props.contents;
    const CardItems = cards.map((card) =>
        <li key={card}>
            {card}
        </li>
    )
    return (
        <main>
            <div className="content">
                <h3>{props.title}</h3>
                <ul>
                    {CardItems}
                </ul>
            </div>
            <Callout/>
        </main>
    )
}

export default PageGrid;
