import { NavLink } from "react-router-dom";

function Navigation(){

    return (
        <nav>
            <NavLink to="/">Home</NavLink>
            <NavLink to="/piano">Piano Lessons</NavLink>
            <NavLink to="/theory">Theory Lessons</NavLink>
            <NavLink to="/composition">Composition Lessons</NavLink>
            <NavLink to="/qualifications">Qualifications</NavLink>
            <NavLink to="/awards">Awards</NavLink>
            <NavLink to="/compositions">Compositions</NavLink>
        </nav>
    )

}

export default Navigation;
