import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

import './App.css';
import Header from "./components/header";
import PageText from "./components/page-text";
import PageGrid from "./components/page-grid";
import Footer from "./components/footer";
import PagePlaylist from "./components/page-playlist";

import gridPages from "./components/data/data-gridpages.json";
import textPages from "./components/data/data-textpages.json";

function App() {
    return (
        <div className="body">
            <Router>
                <Header/>
                <Routes>
                    <Route
                        path="/"
                        element={<PageText title="About Heva" contents={textPages["home"]}/>}/>
                    <Route
                        path="/piano"
                        element={<PageText title="Piano Lessons" contents={textPages["piano"]}/>}/>
                    <Route
                        path="/theory"
                        element={<PageText title="Theory Lessons" contents={textPages["theory"]}/>}/>
                    <Route
                        path="/composition"
                        element={<PageText title="Composition Lessons" contents={textPages["composition"]}/>}/>
                    <Route
                        path="/qualifications"
                        element={<PageGrid title="Qualifications" contents={gridPages["qualifications"]}/> } />
                    <Route
                        path="/awards"
                        element={<PageGrid title="Awards" contents={gridPages["awards"]}/>}/>
                    <Route
                    path="/compositions"
                    element={<PagePlaylist title="Compositions"/>}/>
                </Routes>
                <Footer/>
            </Router>

        </div>
    );
}

export default App;
