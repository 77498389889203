import Callout from "./callout";

function PageText(props){
    return (
        <main>
            <div className="content">
                <h3>{props.title}</h3>
                <p>{props.contents}</p>
            </div>
        <Callout/>
        </main>
    )
}

export default PageText;
